<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow">
      <div class="col-lg-6 img-container">
        <img src="../../src/assets/aa-1.png" class="img-fluid" />
        <div class="contact">
          <div>
            <a href="/regulamin"><span class="text">Statute</span></a>
            <!-- <span class="info">office@cashflowstatement.onlinessssssssssssssss</span> -->
          </div>
          <div>
            <a href="/polityka"><span class="text">Privacy policy</span></a>
            <!-- <span class="info">office@cashflowstatement.onlinessssssssssssssss</span> -->
          </div>
          <div>
            <span class="text">Email:</span>
            <span class="info">office@cashflowstatement.online</span>
          </div>
        </div>
      </div>

      <div class="col-lg-6 secondCol">
        <h1 class="title">In case of interest <br/> please contact</h1>
        <form @submit.prevent="handleSendEmail" class="form shadow box">
          <p class="form-title">Contact Form</p>
          <label for="name">Your name</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="name"
              aria-describedby="basic-addon3"
              v-model="firstName"
            />
          </div>
          <label for="lastName">Your Surname</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="lastName"
              aria-describedby="basic-addon3"
              v-model="lastName"
            />
          </div>
          <label for="email">Your Email</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="email"
              aria-describedby="basic-addon3"
              v-model="email"
            />
          </div>
          <label for="email">Phone number</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="phone"
              v-model="phone"
            />
          </div>
          <label for="message">Message</label>
          <div class="input-group mb-3">
            <textarea
              type="text"
              rows="4"
              class="form-control"
              id="message"
              aria-describedby="basic-addon3"
              v-model="message"
            />
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              v-model="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              required
            />
            <label
              class="form-check-label"
              for="exampleCheck1"
              style="font-size:0.6rem;"
              >I consent to the processing of my personal data
              <br />
              in accordance with the Personal Data Protection Act</label
            >
          </div>
          <div class="text-right">
            <button type="submit" class="mt-4 btn btn-yellow ">
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >SEND</span
              >
            </button>
          </div>
          <div style="width: 100%">
            <div
              v-if="user_message == 200"
              class="alert alert-success"
              role="alert"
            >
              Email has been sent.
            </div>
            <div
              v-else-if="user_message"
              class="alert alert-danger"
              role="alert"
            >
              {{ message.message }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "Other",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      checkbox: false,
      user_message: "",
    };
  },
  methods: {
    handleSendEmail() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        UserService.getSendEmailHelp(
          this.firstName,
          this.lastName,
          this.email,
          this.phone,
          this.message
        ).then(
          (response) => {
            this.user_message = response.status;
            setTimeout(() => (this.user_message = ""), 2000);
          },
          (error) => {
            this.user_message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.user_message = ""), 2000);
          }
        );
        this.loading = false;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
        this.message = "";
        this.checkbox = false;
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/css/main.css";
.container-fluid {
  padding: 0;
}
.title {
  margin: 60px 0;
  font-size: 30px;
  text-align: center;
}

.contact {
  z-index: 9999;
  width: 50%;
  height: 10%;
  font-size: 14px;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  left: 0;
  bottom: 0;
  background-color: black;
}
.text {
  color: white;
  font-style: italic;
  font-weight: bold;
  margin-right: 5px;
}
.info {
  color: rgba(252, 240, 3, 1);
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

label {
  font-size: 0.75rem;
  font-weight: bold;
}
@media only screen and (max-width: 520px) {
  .box {
    width: 100%;
  }
}
</style>
